import { Box, MenuItem, Stack } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  CIVIL_STATUS_OPTIONS,
  GENDER_OPTIONS,
} from '#/components/pages/PensionProvision/onboarding/data';
import PhoneInput from '#/components/shared/hook-form/PhoneInput';

export default function CoreForm() {
  const { watch, setValue } = useFormContext();
  const { translate } = useLocales();

  const handlePhoneChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue('mobile_phone', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <RHFTextField
          name="first_name"
          label={String(translate('global.formLabels.firstName'))}
        />
        <RHFTextField
          name="last_name"
          label={String(translate('global.formLabels.lastName'))}
        />
        <PhoneInput
          name="mobile_phone"
          label={String(translate('global.formLabels.mobilePhone'))}
          value={watch('mobile_phone')}
          onChange={(e) => handlePhoneChange(e)}
          sx={{ width: '100%' }}
        />
      </Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}
        >
          <RHFDatePicker
            variant="outlined"
            name="date_of_birth"
            label={String(translate('global.formLabels.date_of_birth'))}
          />
          <RHFSelect name="gender" label={String(translate('global.gender'))}>
            {GENDER_OPTIONS.map((g, i) => (
              <MenuItem key={i} value={g.value}>
                {String(translate(`global.${g.label}`))}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          sx={{ width: '100%' }}
        >
          <RHFSelect
            variant="outlined"
            name="civil_status"
            label={String(translate('global.formLabels.civilStatus'))}
          >
            {CIVIL_STATUS_OPTIONS.map((status) => (
              <MenuItem key={status.code} value={status.code}>
                {String(translate(`global.civilStatus.${status.code}`))}
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFTextField
            name="number_of_children"
            label={String(translate('global.formLabels.children_count'))}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
