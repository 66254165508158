import { Box, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import useLocales from '#/hooks/useLocales';
import { coreQuestions } from '#/components/pages/PensionProvision/onboarding/data';
import RHFStyledRadioGroup from '#/components/shared/hook-form/RHFStyledRadioGroup';
import { RHFTextField } from '#/components/shared/hook-form';

export default function FirstStep() {
  const { watch } = useFormContext();
  const { translate } = useLocales();
  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const forMyself = watch('provision_for') === 'self';

  const translatedQuestions = coreQuestions.map((question, i) => ({
    ...question,
    question: String(
      translate(`pensionProvision.onboarding.stepOne.questions.${i}.question`, {
        referringTo: translate(
          `pensionProvision.onboarding.stepOne.${
            forMyself ? 'myRealEstate' : 'theirRealEstate'
          }`
        ),
      })
    ),
    answers: question.answers?.map((answer, j) => ({
      ...answer,
      label: String(
        translate(
          `pensionProvision.onboarding.stepOne.questions.${i}.options.${j}.label`
        )
      ),
    })),
  }));

  return (
    <Box
      sx={{
        width: '100%',
        '& > div': {
          marginTop: 3,
        },
      }}
    >
      <Typography variant="subtitle1">
        {String(translate('pensionProvision.onboarding.stepOne.subtitle'))}
      </Typography>
      <Stack spacing={3}>
        {translatedQuestions.map((question) => (
          <div
            key={question.questionKey}
            ref={(ref) => {
              refs.current[`${question.questionKey}`] = ref;
            }}
          >
            <Stack direction="column" spacing={2}>
              {question.answers && (
                <RHFStyledRadioGroup
                  name={question.questionKey}
                  question={{
                    questionKey: question.questionKey,
                    question: question.question,
                    answers: question?.answers,
                  }}
                />
              )}
              {question.children && question.children.content()}
            </Stack>
          </div>
        ))}
      </Stack>
    </Box>
  );
}

export function EmailAddress() {
  const { translate } = useLocales();

  return (
    <Stack spacing={1}>
      <RHFTextField
        variant="outlined"
        name="email"
        label={String(translate('global.formLabels.emailAddress'))}
      />
    </Stack>
  );
}
